<template>
  <div class="animated fadeIn">
    <CardTable :title="title" :columns="table.columns" :rows="table.rows" :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize" :pageNumber="serverParams.pageNumber" :totalPage="table.totalPage"
               :totalRecords="table.totalRecords" :pagination="false" @onPageChange="onPageChange"
               :stickyHeader="`${tableHeight(300)}px`">
      <template #action>
        <b-row class="d-flex flex-row mt-2">
          <b-col sm="3">
            <BuildingDropdown :server-params="serverParams" />
          </b-col>
        </b-row>
      </template>
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" @onExport="exportAll" />
      </template>
      <template #cell(deposit)="data">
        {{ numberFormat(data.item.deposit) }}
      </template>
      <template #cell(totalOutstandingAmount)="data">
        {{ numberFormat(data.item.totalOutstandingAmount) }}
      </template>
      <template #cell(overdueAmount)="data">
        {{ numberFormat(data.item.overdueAmount) }}
      </template>
      <template #custom-foot style="inset-block-end: 0; width: 100%;" v-if="!table.isLoading">
        <b-th class="text-center" colspan="3">TOTAL</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.deposit) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.totalOutstandingAmount) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.latestMonthOutStandingSum) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.secondLastMonthOutStandingSum) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.thirdLastMonthOutStandingSum) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.otherBillingSum) }}</b-th>
        <!--<b-th class="text-right">{{ numberFormat(table.summary.overdueAmount) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.currentAmount) }}</b-th>-->
      </template>
    </CardTable>
  </div>
</template>

<style>
tfoot,
tfoot th,
tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 4;
}

tfoot th:first-child {
  z-index: 5;
}
</style>

th


<script>
  import { reportDebtorAging as columns } from "@/shared/columns";
  import { getDate, numberFormat, tableHeight } from "@/shared/utils";
  import SectionFilter from "./SectionFilter.vue";
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    components: {
      BuildingDropdown,
      SectionFilter,
    },
    data: () => ({
      title: "Debtor Aging Report",
      serverParams: {
        pageNumber: 1,
        pageSize: 100,
        selectedDate: getDate(),
      },
      table: {
        isLoading: true,
        columns: columns,
        rows: [],
        totalPage: 0,
        totalRecords: 0,
      },
    }),
    created() {
      const self = this;
      self.getAll();
    },
    methods: {
      numberFormat,
      tableHeight,
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      getAll() {
        const self = this;

        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/report/debtor-aging",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              const data = response.data.data.map(data => {
                const flattenedData = Object.assign({}, data, data.monthsDict);
                delete flattenedData.MonthsDict;
                return flattenedData;
              });
              self.table.rows = data;
              self.table.summary = response.data.additionalData;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
              //dynamic cols logic
              const months = response.data.additionalData.months.map(x => ({ key: x.monthName, label: x.monthName, sortable: true }));
              self.table.columns = [...columns, ...months,
              {
                label: "Other Billing",
                key: "otherBillingAmount",
                sortable: true,
              }];
              // self.table.columns.map(x => console.log(x.key, x.label));
            }
            self.table.isLoading = false;
          });
      },
      exportAll() {
        const self = this;

        self.$store
          .dispatch("apis/download", {
            url: "/report/debtor-aging/export",
            params: self.serverParams,
          })
          .then((response) => {
            console.log(response)
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              let fileURL = window.URL.createObjectURL(new Blob([response])),
                fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", "debtor-aging-report.xls");
              document.body.appendChild(fileLink);

              fileLink.click();
            }
          });
      },
    },
  };
</script>
