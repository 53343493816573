<template>
  <div>
    <div class="px-3 py-2">
      <b-row class="align-items-center">
        <b-col sm="2">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="icon-calendar"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              type="date"
              class="form-control"
              v-model="serverParams.selectedDate"
            />
          </b-input-group>
        </b-col>
        <b-col sm="10">
          <b-button variant="primary" @click="onChange">View Report</b-button>
          <b-button class="ml-2" variant="primary" @click="onExport">Export Report</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serverParams: Object,
  },
  methods: {
    onChange() {
      this.$emit("onChange", this.serverParams);
    },
    onExport() {
      this.$emit("onExport", this.serverParams);
    },
  },
};
</script>
